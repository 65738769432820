import React, { useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRobot,
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import ReactMarkdown from "react-markdown";
import LoadingText from "../utils/LoadingText";
import GSearch from "../utils/gSearch";
import LinkSearch from "../utils/linkSearch";
import { errorTool } from "../utils/chatHelper";
import { useAuth } from "../../../utils/AuthProvider";
import Feedback from "../utils/feedback";
import EventError from "../utils/eventError";
import mainLogo from "../../../assets/babylon.png";
import { recorderToolkit } from "../utils/recorderHelper";
import { BlockMath, InlineMath } from "react-katex";
import "katex/dist/katex.min.css";
import katex from "katex";
import rehypeKatex from "rehype-katex";
import { preprocessLaTeX } from "../utils/chatHelper";

const MessageList = React.memo(
  ({
    messages,
    setMessages,
    isLoading,
    setIsLoading,
    messagesEndRef,
    hasError,
    setHasError,
    handleRegenerate,
    stopStream,
    didSearch,
    setDidSearch,
    linkSearch,
    setLinkSearch,
    data,
    setData,
    messageId,
    setMessageId,
    threadId,
    handleSendMessage,
    setRunId,
    eventSourceRef,
    setWait,
    eventError,
    wait,
    isRecording,
    setIsRecording,
  }) => {
    const { token } = useAuth();
    const [isAnalyzing, setIsAnalyzing] = useState(false);

    return (
      <>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-2 p-1 rounded flex ${
              message.sender === "assistant" || message.role === "assistant"
                ? "justify-start"
                : "justify-end"
            } w-full`}
          >
            {(message.sender === "assistant" ||
              message.role === "assistant") && (
              <div className="flex flex-col items-center">
                <img src={mainLogo} className={`mr-1 h-6 w-6 max-w-sm`} />
              </div>
            )}

            <div
              className={`p-1 sm:max-w-[75%] rounded ${
                message.sender === "assistant" || message.role === "assistant"
                  ? "bg-white"
                  : "bg-green-300"
              }`}
              style={{ overflowWrap: "break-word", wordBreak: "break-word" }}
            >
              {message.role === "assistant" &&
              index === messages.length - 1 &&
              isLoading ? (
                <LoadingText
                  text={
                    message.isLoading
                      ? "Loading..."
                      : message.isAnalyzing
                      ? "Analyzing message..."
                      : message.isProcessing
                      ? "Processing..."
                      : "Please wait..."
                  }
                  isLoading={isLoading}
                />
              ) : (
                <div className="markdown-content">
                  {didSearch && (
                    <GSearch
                      message={message}
                      messages={messages}
                      index={index}
                    />
                  )}
                  {linkSearch && (
                    <LinkSearch
                      data={data}
                      index={index}
                      message={message}
                      messages={messages}
                    />
                  )}
                  {eventError && (
                    <EventError
                      index={index}
                      message={message}
                      messages={messages}
                    />
                  )}

                  <ReactMarkdown
                    remarkPlugins={[remarkMath, remarkGfm]}
                    rehypePlugins={[rehypeKatex]}
                    components={{
                      code: ({ node, inline, className, children, ...props }) =>
                        inline ? (
                          <code className="bg-green-200 rounded p-1">
                            {children}
                          </code>
                        ) : (
                          <pre
                            {...props}
                            className="bg-gray-200 rounded p-2 overflow-hidden"
                          >
                            <code>{children}</code>
                          </pre>
                        ),
                      a: ({ href, children, ...props }) => (
                        <a
                          href={href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                          {...props}
                        >
                          {children}
                        </a>
                      ),
                    }}
                  >
                    {preprocessLaTeX(message.text_value || "***")}
                  </ReactMarkdown>

                  {message?.role === "assistant" && !eventError && (
                    <Feedback threadId={threadId} />
                  )}
                  {message.attachments?.[0]?.file_id ? (
                    <div className="bg-gray-50 border border-blue-300 p-3 mt-3 text-sm text-blue-600 rounded-lg shadow-sm flex items-center space-x-3 hover:bg-blue-100 transition duration-200 ease-in-out">
                      <div className="bg-blue-100 p-2 rounded-full text-blue-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="font-medium">File ID:</p>
                        <p className="text-sm">
                          {message.attachments[0].file_id}
                        </p>
                      </div>
                    </div>
                  ) : (message.filename && message.filename !== "No File") ||
                    message.file ? (
                    <div className="bg-gray-50 border border-green-300 p-3 mt-3 text-sm text-green-600 rounded-lg shadow-sm flex items-center space-x-3 hover:bg-green-100 transition duration-200 ease-in-out">
                      <div className="bg-green-100 p-2 rounded-full text-green-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 7a1 1 0 0 1 1-1h4l2 2h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7z"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="font-medium">File:</p>
                        <p className="text-sm">
                          {message.filename || message.file}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            {(message.sender === "user" || message.role === "user") && (
              <FontAwesomeIcon icon={faUser} className="ml-1 text-green-500" />
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
        {errorTool({
          hasError,
          setHasError,
          token,
          messageId,
          threadId,
          messages,
          setMessages,
          handleSendMessage,
          setIsLoading,
          setWait,
          setLinkSearch,
          setDidSearch,
          setRunId,
          setMessageId,
          setData,
          eventSourceRef,
        })}
      </>
    );
  }
);

export default MessageList;
