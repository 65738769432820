import React, { useState, useRef, useEffect } from "react";

//component for recording voice
const AudioRecorder = ({
  onRecordingComplete,
  startButtonText = "Start Recording",
  stopButtonText = "Stop Recording",
  showVisualizer = true,
  classes = "",
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setSeconds((prev) => {
          if (prev === 59) {
            setMinutes((m) => m + 1);
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    } else {
      setMinutes(0);
      setSeconds(0);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  // Handle Start Recording
  const handleStartRecording = () => {
    // Check if getUserMedia is supported
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording.");
      return;
    }

    setIsRecording(true);
    audioChunksRef.current = [];

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);

          onRecordingComplete && onRecordingComplete(audioBlob, audioUrl);

          stream.getTracks().forEach((track) => track.stop());
        };

        mediaRecorderRef.current.start();
      })
      .catch((error) => {
        console.error("Error accessing audio devices:", error);
        alert(
          "Could not access your microphone. Please check your device and browser permissions."
        );

        setIsRecording(false);
      });
  };

  // Handle Stop Recording
  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
  };

  return (
    <div
      className={`${classes} flex flex-col items-center justify-center p-6 sm:p-4 lg:p-3 w-full max-w-lg lg:max-w-full lg:flex-row lg:space-x-6 mb-4 bg-gray-50 rounded-lg shadow-lg`}
    >
      <div className="flex flex-col items-center mb-4 lg:mb-0 lg:flex-row lg:space-x-3">
        <div
          className={`w-16 h-16 rounded-full flex items-center justify-center border-4 ${
            isRecording
              ? "border-red-500 bg-red-100 animate-pulse"
              : "border-gray-300 bg-gray-200"
          } transition-all duration-300`}
        >
          <span className="text-lg font-bold text-gray-800">
            {isRecording
              ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
              : "00:00"}
          </span>
        </div>
        <p className="text-md text-gray-600 lg:ml-3">
          {isRecording ? "Recording..." : "Ready to record"}
        </p>
      </div>

      <button
        onClick={isRecording ? handleStopRecording : handleStartRecording}
        className={`flex items-center justify-center ${
          isRecording
            ? "bg-red-500 hover:bg-red-600"
            : "bg-green-500 hover:bg-green-600"
        } text-white text-md font-semibold py-2 px-8 rounded-full shadow transition-colors duration-300 transform hover:scale-105`}
        aria-label={isRecording ? "Stop Recording" : "Start Recording"}
      >
        <span className="ml-2">
          {isRecording ? stopButtonText : startButtonText}
        </span>
      </button>

      {audioUrl && (
        <div className="mt-4 lg:mt-0 lg:ml-6 w-full">
          <audio controls className="w-full rounded-lg bg-gray-200">
            <source src={audioUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
