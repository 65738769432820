import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/NavBar/NavBar";
import Chatbot from "../../components/ChatBot/ChatBot";
import Testimonials from "../../components/Testimonials/Testimonials";
import ContactEmail from "../../components/ContactEmail/ContactEmail";
import Footer from "../../components/Footer/Footer";
import Scroll from "./Scroll";
import TypewriterText from "../../hooks/useType";
import { FiArrowUp, FiPhone } from "react-icons/fi";

export default function Home() {
  const [play, setPlay] = useState(false);
  const [fade, setFade] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isAtContactSection, setIsAtContactSection] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (isAtContactSection) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      contactEmailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleScroll = () => {
    if (contactEmailRef.current) {
      const contactPosition =
        contactEmailRef.current.getBoundingClientRect().top + window.scrollY;
      const currentPosition = window.scrollY + window.innerHeight;

      if (currentPosition >= contactPosition) {
        setIsAtContactSection(true);
      } else {
        setIsAtContactSection(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const contactEmailRef = useRef(null);

  const openModal = () => {
    setIsModal(true);
  };

  const handleVideoEnd = () => {
    setFade(true);
    setTimeout(() => {
      setPlay((prev) => !prev);
      setFade(false);
    }, 700);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsModal(true);
    }, 1500);
  }, []);
  return (
    <div className="">
      <div className="fixed right-7 bottom-[19%] sm:bottom-[16%] sm:right-6 z-50">
        <button
          onClick={handleButtonClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={`flex items-center justify-center h-14 bg-green-600 rounded-full shadow-md transition-all duration-200 ${
            isHovered ? "w-56 px-4" : "w-14"
          }`}
        >
          {isAtContactSection ? (
            <FiArrowUp className="text-white text-xl" />
          ) : (
            <FiPhone className="text-white text-xl" />
          )}
          {isHovered && (
            <span className="ml-4 text-white text-lg font-medium transition-opacity duration-200">
              {isAtContactSection ? "Scroll to Top" : "Contact Us"}
            </span>
          )}
        </button>
      </div>

      <Navbar />
      <section className="relative z-10 bg-gradient-to-r from-green-700 via-green-400 to-green-700 text-gray-900 w-full flex items-center px-4 py-6 sm:py-12">
        <div
          data-aos="flip-down"
          className="flex flex-col sm:flex-row items-center justify-around w-full"
        >
          <div className="relative w-full sm:w-1/2 max-w-lg text-center sm:text-left">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl text-white font-extrabold leading-tight mb-4">
              Transforming Accounting with{" "}
              <span className="text-yellow-400 underline decoration-wavy">
                AI Precision
              </span>{" "}
              & Human Expertise
            </h1>
            <p className="text-sm sm:text-md lg:text-lg text-white font-light mb-6">
              Intelligent solutions tailored for accountants, entrepreneurs, and
              organizations worldwide—from bustling cities to remote corners.
            </p>
            <a
              href="#getstarted"
              className="inline-block bg-yellow-500 text-black font-bold mb-8 sm:mb-0 px-6 py-3 text-sm sm:text-md lg:text-lg rounded-full shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
              onClick={(e) => {
                navigate("/signup");
                window.scrollTo(0, 0);
              }}
            >
              Get Started →
            </a>
          </div>
          <Chatbot openModal={openModal} />
        </div>
      </section>

      <section className="bg-gradient-to-r from-green-700 via-green-400 to-green-700 py-8 sm:py-16 px-2 relative min-h-auto sm:min-h-[75vh]">
        <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between space-y-8 sm:space-y-0 sm:space-x-4">
          <div
            className="flex-1 text-center sm:pr-8 h-[40vh]"
            data-aos="fade-up"
          >
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-white leading-tight break-words flex items-center justify-center h-full">
              <TypewriterText />{" "}
            </h2>
          </div>

          <div
            className="flex-1 flex justify-center sm:justify-start sm:p-1 max-w-xs sm:max-w-md"
            data-aos="fade-down"
          >
            <img
              src="https://bethaibucket.s3.us-east-1.amazonaws.com/images/botmascot.gif"
              alt="Mascot for Accounting Chatbot Philippines Artifical Intelligence"
              className="w-auto h-full max-h-full object-contain"
            />
          </div>
        </div>
      </section>

      <div className="bg-gradient-to-r from-green-700 via-green-400 to-green-700 py-16 px-4">
        <div className="flex flex-col items-center justify-center mx-auto space-y-6">
          <div className="w-full text-center">
            <p className="text-2xl sm:text-3xl md:text-4xl font-bold text-white">
              Witness the Future with{" "}
              <span className="text-yellow-400">B.E.T.H.</span> in Action
            </p>
          </div>
          <div className="w-full sm:w-4/5 p-2 overflow-hidden">
            <video
              className={`relative z-10 w-full h-auto max-h-96 rounded-lg object-contain transition-opacity duration-700 ${
                fade ? "opacity-0" : "opacity-100"
              }`}
              src={
                play
                  ? "https://bethaibucket.s3.us-east-1.amazonaws.com/videos/landingvid2.mp4"
                  : "https://bethaibucket.s3.us-east-1.amazonaws.com/videos/BETH_Video_09042024.mp4"
              }
              muted
              controls
              autoPlay={play.toString()}
              loop={false}
              onEnded={handleVideoEnd}
            />
          </div>
        </div>
      </div>

      <div className="px-10 mt-10">
        <section className="flex flex-col lg:flex-row-reverse items-center text-center md:text-left py-12 lg:py-16">
          <div className="w-full lg:w-2/3 flex justify-center mb-6 lg:mb-0 px-4">
            <div className="relative w-full max-w-sm sm:max-w-md lg:max-w-lg shadow-lg rounded-lg overflow-hidden">
              <img
                src="pic5.jpeg"
                alt="About B.E.T.H. AI Chatbot Philippines"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4 lg:px-8">
            <h2 className="text-3xl lg:text-4xl font-extrabold text-black mb-6">
              About <span className="text-green-500">B.E.T.H.</span>
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              B.E.T.H. is the ultimate AI platform designed for professionals in
              auditing, tax, and accounting. Instantly get accurate answers to
              your complex financial and compliance questions.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              Join our community and lead the future of the accounting industry.
              B.E.T.H. helps you leverage AI to stay ahead and be the expert in
              your field.
            </p>
            <div className="flex justify-center lg:justify-start">
              <Link
                to="/signup"
                onClick={() => window.scrollTo(0, 0)}
                className="inline-block bg-green-500 hover:bg-green-600 text-white font-semibold px-6 py-3 rounded-full shadow-md transition-all duration-300"
              >
                Register Now
              </Link>
            </div>
          </div>
        </section>

        <div className="w-full flex flex-col sm:flex-row items-center justify-between mt-8">
          <Scroll />
          <div className="flex flex-col text-center sm:text-left my-8 sm:my-0">
            <p className="text-2xl sm:text-3xl font-bold text-black max-w-lg">
              Where accuracy meets authoritative knowledge.
            </p>
            <p className="text-md text-gray-700 max-w-lg mt-4">
              Learn more about our reliable sources{" "}
              <Link to="/sources" className="text-green-500 underline">
                here
              </Link>
              .
            </p>
          </div>
        </div>

        <section className="relative z-10 mt-12 py-16 bg-white rounded-lg shadow-lg">
          <div className="container mx-auto text-center">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-extrabold text-black">
                Our Features
              </h2>
              <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
                Discover the powerful features that make our application stand
                out.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
              <div className="flex flex-col items-center bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-xl transition-all duration-300">
                <img
                  src="fast.svg"
                  alt="Instant Research of Accounting Chatbot Philippines"
                  className="h-16 w-16 mb-4"
                />
                <h3 className="text-xl font-semibold text-black mb-2">
                  Instant Research
                </h3>
                <p className="text-md text-gray-500 text-center">
                  Ask for solutions for Tax, Accounting, Audit, and Licensing in
                  seconds.
                </p>
              </div>

              <div className="flex flex-col items-center bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-xl transition-all duration-300">
                <img
                  src="search.svg"
                  alt="Quick Email Response of BETH AI"
                  className="h-16 w-16 mb-4"
                />
                <h3 className="text-xl font-semibold text-black mb-2">
                  Quick Email Response
                </h3>
                <p className="text-md text-gray-500 text-center">
                  Give B.E.T.H. a brief prompt, and it’ll craft a professional
                  email for proposals, pitches, or client responses.
                </p>
              </div>
            </div>

            <div className="flex justify-center mt-12">
              <Link
                to="/features"
                onClick={scrollToTop}
                className="w-full max-w-xs h-12 text-lg bg-green-500 text-white flex items-center justify-center rounded-full shadow-md hover:bg-green-600 transition-all duration-300"
              >
                Learn More
              </Link>
            </div>
          </div>
        </section>

        <Testimonials />
        <ContactEmail ref={contactEmailRef} />
      </div>
      <Footer />
    </div>
  );
}
