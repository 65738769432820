import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, subDays, isToday, isWithinInterval } from "date-fns";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import api from "../../utils/api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import useClickOutside from "../../hooks/useClickOutside";

import {
  regroupThreadsByDate,
  getCachedHistory,
  setCachedHistory,
  removeCachedData,
} from "./cache/caching";

export default function SideBar({
  token,
  threadId,
  userLevel,
  setThreadId,
  setMessages,
  logout,
  sidebarVisible,
  setSidebarVisible,
  openModal,
  wait,
  setWait,
  loading,
  darkMode,
}) {
  const [history, setHistory] = useState({
    today: [],
    yesterday: [],
    last7Days: [],
    last14Days: [],
    last30Days: [],
    older: [],
  });
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [renamingThreadId, setRenamingThreadId] = useState(null);
  const [newThreadName, setNewThreadName] = useState("");
  const [threads, setThreads] = useState({});
  const modalRef = useRef(null);
  const dropdownRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    //use to update the thread name
    const fetchThreads = () => {
      const sidebarHistory = JSON.parse(
        localStorage.getItem("sidebar_history") || "{}"
      );
      setThreads(sidebarHistory.data || {});
    };

    fetchThreads();

    const handleStorageChange = (e) => {
      if (e.key === "sidebar_history") {
        fetchThreads();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setIsFetching(true);
        setError(null);

        // Check cache first
        const cachedData = getCachedHistory();
        if (cachedData) {
          setHistory(cachedData);
          setIsFetching(false);
          return;
        }

        // Fetch history, no cache found
        const response = await api.get("/chat/history", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const historyData = response.data;

        const grouped = regroupThreadsByDate(historyData);

        setHistory(grouped);
        setCachedHistory(grouped);
        setIsFetching(false);
      } catch (error) {
        setError("Error getting history");
        setIsFetching(false);
        console.error("Error getting history:", error);
      }
    };

    fetchHistory();
  }, [token, refresh]);

  const formatPeriodLabel = (period) => {
    switch (period) {
      case "today":
        return "Today";
      case "yesterday":
        return "Yesterday";
      case "last7Days":
        return "Last 7 Days";
      case "last14Days":
        return "Last 14 Days";
      case "last30Days":
        return "Last 30 Days";
      case "older":
        return "Older";
      default:
        return period;
    }
  };

  const handleThreadClick = async (threadId) => {
    if (loading || isFetching || wait) return;

    const cachedMessages = JSON.parse(
      localStorage.getItem(`messages_${threadId}`)
    );
    if (cachedMessages) {
      localStorage.setItem("threadId", threadId);
      setMessages(cachedMessages);
      setThreadId(threadId);
      return;
    }

    setThreadId(threadId);
    localStorage.setItem("threadId", threadId);
    setIsFetching(false);
  };

  const handleNewSession = async () => {
    if (isFetching || wait) return;

    setIsFetching(true);
    try {
      const response = await api.post("/chat/new/thread", null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Create new thread object
      const newThread = {
        id: response.data.id,
        thread_id: response.data.thread_id,
        name: response.data.name,
        last_used: response.data.last_used,
      };

      // Update history state and cache
      const updatedHistory = addThreadToHistory(newThread, history);
      setHistory(updatedHistory);
      setCachedHistory(updatedHistory);

      // Set current thread
      setThreadId(response.data.thread_id);
      setMessages([]);

      setRefresh(!refresh);
    } catch (error) {
      setError("Error creating new session");
      console.error("Error creating new session:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const addThreadToHistory = (newThread, currentHistory) => {
    // Format thread data to match history structure
    const threadData = {
      id: newThread.id,
      thread_id: newThread.thread_id,
      name: newThread.name,
      last_used: newThread.last_used,
    };

    // Combine with existing threads and regroup
    const allThreads = [threadData, ...Object.values(currentHistory).flat()];
    return regroupThreadsByDate(allThreads);
  };

  {
    /*Below this handles the renaming of the thread from caching to calling of api*/
  }
  const handleRename = (threadId) => {
    setRenamingThreadId(threadId);
    const thread = Object.values(history)
      .flat()
      .find((item) => item.thread_id === threadId);
    setNewThreadName(thread ? thread.name : "");
  };

  const submitRename = async (threadId) => {
    try {
      await api.post(
        `/chat/thread/rename?thread_id=${threadId}&new_name=${newThreadName}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      renameThread(threadId, newThreadName);
      setRenamingThreadId(null);
      setRefresh(!refresh);
    } catch (error) {
      setError("Error renaming thread");
      console.error("Error renaming thread:", error);
    }
  };

  const renameThread = (threadId, newName) => {
    const updatedHistory = { ...history };
    Object.keys(updatedHistory).forEach((period) => {
      updatedHistory[period] = updatedHistory[period].map((thread) => {
        if (thread.thread_id === threadId) {
          return { ...thread, name: newName };
        }
        return thread;
      });
    });

    // Update the state and cache
    setHistory(updatedHistory);
    setCachedHistory(updatedHistory);
  };

  const handleDelete = async (threadId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this thread?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await api.delete(`/chat/history/thread/delete/${threadId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              sessionStorage.clear();
              localStorage.clear();
              window.location.reload();
            } catch (error) {
              console.error("Error deleting thread:", error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleButtonClick = (e, action) => {
    e.stopPropagation();
    e.preventDefault();
    action();
    setDropdownOpen(null);
  };

  const getLinkClasses = (id) => {
    return id === threadId ? "font-bold" : "";
  };

  useClickOutside(dropdownRef, buttonRef, () => setDropdownOpen(false));

  return (
    <div
      className={`flex flex-col h-screen border-r-2 transition-colors duration-300 ${
        darkMode
          ? "bg-gray-800 border-gray-800 "
          : "bg-gradient-to-r from-green-50 to-blue-50 text-gray-800"
      }`}
    >
      {/* Header Section */}
      <div className="flex justify-between items-center p-4">
        <button
          className={`focus:outline-none transition-colors ${
            darkMode
              ? "text-gray-400 hover:text-gray-200"
              : "text-gray-600 hover:text-gray-800"
          }`}
          onClick={() => setSidebarVisible(!sidebarVisible)}
          title="Ellipsis Menu Bar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {sidebarVisible ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
      </div>

      <div className="p-4">
        <button
          onClick={handleNewSession}
          className="bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 text-white rounded-lg p-2 mb-2 w-full  disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors duration-200"
          disabled={isFetching}
        >
          New Chat
        </button>
      </div>

      <ul className="flex-grow overflow-y-auto p-4 space-y-4 max-h-[calc(100vh-200px)]">
        {Object.entries(history).map(
          ([period, items]) =>
            items.length > 0 && (
              <div key={period}>
                <h3 className="text-sm font-medium text-gray-500 dark:text-white mb-2">
                  {formatPeriodLabel(period)}
                </h3>
                {items.map((item) => (
                  <li
                    key={item.thread_id}
                    className={`flex justify-between items-center p-2 m-2 rounded-md text-xs transition-colors duration-200 ${
                      item.thread_id === threadId
                        ? "bg-green-100 text-green-700 dark:bg-green-800 dark:text-blue-100"
                        : loading
                        ? "cursor-not-allowed bg-gray-100 text-gray-400 dark:bg-gray-700 dark:text-gray-500"
                        : "bg-white text-gray-800 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                    }`}
                    onClick={() => handleThreadClick(item.thread_id)}
                  >
                    {renamingThreadId === item.thread_id ? (
                      <input
                        type="text"
                        value={newThreadName}
                        onChange={(e) => setNewThreadName(e.target.value)}
                        onBlur={() => submitRename(item.thread_id)}
                        onKeyDown={(e) =>
                          e.key === "Enter" && submitRename(item.thread_id)
                        }
                        className="border border-gray-300 text-black p-1 text-xs rounded-md w-full"
                        autoFocus
                      />
                    ) : (
                      <p
                        className={`${getLinkClasses(item.thread_id)} truncate`}
                      >
                        {item.name || `Name: ${item.thread_id}`}
                      </p>
                    )}

                    <div className="relative">
                      <button
                        ref={buttonRef}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdownOpen(item.thread_id);
                          setRenamingThreadId(null);
                        }}
                        className="focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded-full"
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          className="text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100"
                        />
                      </button>

                      {dropdownOpen === item.thread_id && (
                        <div
                          className="absolute -right-12 sm:right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-40"
                          ref={dropdownRef}
                        >
                          <button
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                            onClick={(e) => {
                              handleButtonClick(e, () =>
                                handleRename(item.thread_id)
                              );
                              setDropdownOpen(null);
                            }}
                          >
                            Rename
                          </button>

                          <button
                            className="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 w-full text-left"
                            onClick={(e) =>
                              handleButtonClick(e, () =>
                                handleDelete(item.thread_id)
                              )
                            }
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </div>
            )
        )}
      </ul>

      <div className="mt-1">
        <div className="text-sm text-center pb-4">
          {userLevel === "free" && (
            <>
              <p className="mb-1 mx-4 sm:mx-2 dark:text-white">
                Not satisfied with the answers?
              </p>
              <p
                className="text-green-500 cursor-pointer hover:underline"
                onClick={openModal}
              >
                Get Premium now
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
