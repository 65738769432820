import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "./api";

//tokens are saved in cookies.

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (decodeURIComponent(cookieName) === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  const [token, setToken] = useState(() => getCookie("auth_token") || null);
  const [userDetails, setUserDetails] = useState();

  const navigate = useNavigate();
  const { auth_token } = useParams();

  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          const response = await api.get(`/user/token/expiry`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.token) {
            setToken(response.data.token);
          } else if (response.data.valid) {
            console.log("Token is still valid");
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            removeCookie("auth_token");
            navigate("/signin");
          } else {
            console.error("Error during token verification:", error);
          }
        }
      }
    };

    verifyToken();
  }, [token, navigate, auth_token]);

  useEffect(() => {
    if (token) {
      setCookie("auth_token", token, { path: "/" });
    } else {
      removeCookie("auth_token");
    }
  }, [token]);

  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        try {
          const response = await api.get(`/user/details`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserDetails(response.data);
          sessionStorage.setItem("user", JSON.stringify(response.data));
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchUser();
  }, [token]);

  const login = (newToken) => {
    setToken(newToken);
    setCookie("auth_token", newToken, { path: "/" });
    setTimeout(() => {
      navigate("/app");
    }, 1000);
  };

  const logout = () => {
    setToken(null);
    removeCookie("auth_token");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin");
  };

  const setCookie = (name, value, options = {}) => {
    options = {
      ...options,
      sameSite: "strict",
    };

    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
      value
    )}`;

    for (let optionKey in options) {
      let optionValue = options[optionKey];
      cookieString += `; ${optionKey}`;
      if (optionValue !== true) {
        cookieString += `=${optionValue}`;
      }
    }

    document.cookie = cookieString;
  };

  const removeCookie = (name) => {
    document.cookie = `${encodeURIComponent(name)}=; Max-Age=0; path=/`;
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, userDetails }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
