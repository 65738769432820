import PhoneInput from "react-phone-input-2";
import { ReactComponent as Loader } from "../../../assets/loader.svg";
import api from "../../../utils/api";
import { useEffect, useState, useRef } from "react";

export default function Billing({
  loading,
  handleSubscribe,
  handlePhoneChange,
  isButtonDisabled,
  phoneNumber,
  selectedPlan,
  setSelectedPlan,
}) {
  const [discounts, setDiscounts] = useState([]);
  const [statusMessage, setStatusMessage] = useState(""); // State for feedback
  const [appliedDiscount, setAppliedDiscount] = useState(null); // State to store the applied discount
  const discountCodeRef = useRef();

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await api.get(`/discount/all`);

        const discountsArray = Array.isArray(response)
          ? response
          : response.data;
        setDiscounts(discountsArray);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };

    fetchDiscounts();
  }, []);

  const handleApply = async () => {
    const discountCode = discountCodeRef.current.value.trim();
    if (!discountCode) {
      setStatusMessage("Please enter a discount code.");
      setAppliedDiscount(null); // Reset the discount
      return;
    }

    const currentDate = new Date();

    const matchedDiscount = discounts.find(
      (discount) =>
        discount.code === discountCode &&
        discount.is_active &&
        new Date(discount.valid_from) <= currentDate &&
        new Date(discount.valid_to) >= currentDate
    );

    if (!matchedDiscount) {
      setStatusMessage("This discount code is invalid, inactive, or expired.");
      setAppliedDiscount(null);
      return;
    }

    try {
      const response = await api.get(`/discount/${discountCode}/details`);
      setStatusMessage(
        `Discount applied successfully! You saved ${matchedDiscount.value}%`
      );
      setAppliedDiscount(matchedDiscount);
    } catch (error) {
      console.error("Error applying discount:", error);
      setStatusMessage("Error applying discount. Please try again.");
      setAppliedDiscount(null);
    }
  };

  const planDetails =
    selectedPlan === "monthly"
      ? { price: 16.99, label: "BETH Premium - Monthly", term: "per month" }
      : { price: 203.88, label: "BETH Premium - Annually", term: "per year" };

  // const discountedPrice =
  //   selectedPlan === "annual"
  //     ? planDetails.price * (1 - 0.2)
  //     : planDetails.price;

  // const discountEarly = 2.0;
  return (
    <form
      onSubmit={handleSubscribe}
      className="max-w-xl mx-auto mt-10 bg-white shadow-lg rounded-lg p-6"
    >
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">
          Subscribe to BETH Premium
        </h2>
        <p className="text-gray-500 mt-2">
          {selectedPlan === "monthly" ? "Billed monthly" : "Billed annually"}
        </p>
      </div>

      {/* Plan Selection */}
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <h3 className="text-lg font-bold text-gray-700 mb-4">
          Choose Your Plan
        </h3>
        <div className="flex justify-center space-x-4">
          <button
            type="button"
            className={`px-4 py-2 rounded-lg text-lg font-semibold border-2 ${selectedPlan === "monthly"
              ? "bg-green-500 text-white"
              : "border-gray-300"
              }`}
            onClick={() => setSelectedPlan("monthly")}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-lg text-lg font-semibold border-2 ${selectedPlan === "annual"
              ? "bg-green-500 text-white"
              : "border-gray-300"
              }`}
            onClick={() => setSelectedPlan("annual")}
          >
            Annual
          </button>
        </div>
      </div>

      {/* Billing Information */}
      <div className="mb-6">
        <h3 className="text-lg font-bold text-gray-700">Billing Information</h3>

        <label htmlFor="email" className="block text-gray-700 mt-4">
          Email
        </label>
        <input
          type="email"
          id="email"
          placeholder="Enter email"
          className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
          required
        />

        <label htmlFor="firstName" className="block text-gray-700 mt-4">
          First Name
        </label>
        <input
          type="text"
          id="firstName"
          placeholder="Enter first name"
          className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
          required
        />

        <label htmlFor="lastName" className="block text-gray-700 mt-4">
          Last Name
        </label>
        <input
          type="text"
          id="lastName"
          placeholder="Enter last name"
          className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
          required
        />
        <label htmlFor="phoneNumber" className="block text-gray-700 mt-4">
          Phone
        </label>
        <div className="relative w-full">
          <PhoneInput
            inputProps={{
              id: "phoneNumber",
              name: "phoneNumber",
              placeholder: "Enter phone number",
            }}
            country={"ph"}
            required
            value={phoneNumber}
            onChange={handlePhoneChange}
            countryCodeEditable={false}
            containerClass="flex items-center w-full border-2 rounded-lg my-2 focus-within:ring-2 focus-within:ring-green-500"
            inputClass="flex-1 p-3 text-gray-800 bg-white border-none outline-none rounded-lg"
            buttonClass="p-2 text-gray-500 hover:bg-gray-100 rounded-lg focus:outline-none"
            dropdownClass="absolute mt-1 w-full border border-gray-300 bg-white rounded-lg shadow-lg"
            searchClass="w-full p-2 text-gray-800 border-b border-gray-300 outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <label
          htmlFor="discountCode"
          className="block text-gray-700 font-semibold mt-4"
        >
          Discount Code
        </label>
        <div className="flex items-center space-x-2 mt-2">
          <input
            type="text"
            id="discountCode"
            ref={discountCodeRef}
            className="flex-grow p-3 border border-gray-300 rounded-lg text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-green-500 focus:outline-none"
            placeholder="Enter discount code"
          />
          <button
            type="button"
            onClick={handleApply}
            className="px-4 py-2 font-semibold text-white bg-green-500 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Apply
          </button>
        </div>
        {statusMessage && (
          <p
            className={`mt-2 text-sm ${
              statusMessage.includes("success")
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {statusMessage}
          </p>
        )}
      </div>

      <h3 className="text-lg font-bold text-gray-700 mb-4">Billing Details</h3>
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        {/* Plan Details */}
        <div className="flex justify-between py-2">
          <p className="text-gray-700">{planDetails.label}</p>
          <p className="font-semibold text-gray-800">
            ${planDetails.price.toFixed(2)}
          </p>
        </div>

        {/* Discount Section */}
        {appliedDiscount ? (
          <div className="flex justify-between py-2">
            <div className="flex flex-col">
              <p className="text-gray-700">Discount</p>
              {appliedDiscount.code && (
                <p className="text-xs italic">-{appliedDiscount.code}</p>
              )}
            </div>
            <p className="font-semibold text-gray-800">
              - $
              {((planDetails.price * appliedDiscount.value) / 100).toFixed(2)}
            </p>
          </div>
        ) : (
          <div className="flex justify-between py-2">
            <p className="text-gray-700">Discount</p>
            <p className="font-semibold text-gray-800">$0.00</p>
          </div>
        )}

        {/* Total Due */}
        <div className="flex justify-between py-2 border-t-2 border-gray-300 pt-2">
          <p className="text-lg font-bold">Total Due</p>
          <p className="text-lg font-bold text-green-600">
            $
            {(
              planDetails.price -
              (appliedDiscount
                ? (planDetails.price * appliedDiscount.value) / 100
                : 0)
            ).toFixed(2)}
          </p>
        </div>
      </div>

      <button
        type="submit"
        disabled={isButtonDisabled}
        className={`w-full py-3 font-bold rounded-lg text-white bg-green-500 hover:bg-green-600 transition-colors ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        {loading ? (
          <Loader id="spinner" className="h-6 w-6 mx-auto" />
        ) : (
          "Subscribe"
        )}
      </button>
    </form>
  );
}
