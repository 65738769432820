import React, { useState, useEffect, useCallback, useRef } from "react";
import api from "../../utils/api";
import { useAuth } from "../../utils/AuthProvider";
import Payments from "./components/Payments";
import Profile from "./components/Profile";
import SideBar from "../../components/SideBar/SideBar";
import Spinner from "../../components/Spinner/Spinner";
import Header from "./components/HeaderUserdashboard";
import MessageList from "./components/MessageList";
import MessageInput from "./components/MessageInput";
import PredefinedQuestions from "./components/PredefinedQuestions";
import Survey from "./utils/survey";
import questions from "./utils/questions.json";
import { encode } from "gpt-tokenizer";
import { useSendMessage } from "./utils/chatHelper";
import Settings from "./components/Settings";

export default function UserDashboard() {
  const { logout, token } = useAuth();
  const [threadId, setThreadId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userLevel, setUserLevel] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [didSearch, setDidSearch] = useState(false);
  const [linkSearch, setLinkSearch] = useState(false);
  const [data, setData] = useState();
  const [wait, setWait] = useState(false);
  const [runId, setRunId] = useState();
  const [messageId, setMessageId] = useState();
  const [eventError, setEventError] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isFetchingSpeech, setIsFetchingSpeech] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [speechToPrompt, setSpeechToPrompt] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode") === "true";
    const savedSpeechToPrompt =
      localStorage.getItem("speechToPrompt") === "true";
    setDarkMode(savedDarkMode);
    setSpeechToPrompt(savedSpeechToPrompt);
  }, []);

  // Sync dark mode with the DOM
  useEffect(() => {
    const root = document.documentElement;
    if (darkMode) {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, [darkMode]);
  const fetchMessagesRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const eventSourceRef = useRef(null);

  useEffect(() => {
    const fetchLevel = async () => {
      if (token) {
        try {
          const response = await api.get(`/user/level`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUserLevel(response.data.user_level);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchLevel();
  }, [token]);
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeModalProf = () => setModalOpenProfile(false);
  const openSettings = () => setSettingsModal(!settingsModal);

  const fetchThreadMessages = useCallback(
    async (threadId) => {
      setThreadId(threadId);
      setLoading(true);
      setMessages([]);
      setEventError(false);

      const cachedMessages = JSON.parse(
        localStorage.getItem(`messages_${threadId}`)
      );
      if (cachedMessages) {
        setLoading(false);
        setMessages(cachedMessages);
        return;
      }

      try {
        const response = await api.get(`/chat/history/thread/${threadId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedMessages = response.data;

        if (fetchedMessages && fetchedMessages.length > 0) {
          setMessages(fetchedMessages);
          localStorage.setItem(
            `messages_${threadId}`,
            JSON.stringify(fetchedMessages)
          );
        } else {
          console.warn("No messages found for the thread.");
          setMessages([]);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
        setMessages([]);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    },
    [threadId]
  );

  useEffect(() => {
    const fetchWordCount = async () => {
      try {
        if (userLevel === "free") {
          const response = await api.get(`/chat/token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setWordCount(response.data?.chat_token || 0);
        }
        if (userLevel === "free") {
          const response = await api.get(`/chat/token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setWordCount(response.data?.chat_token || 0);
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    if (token && userLevel) {
      fetchWordCount();
    }
  }, [token, userLevel, refresh, isLoading]);

  useEffect(() => {
    const fetchHistory = async (retryCount = 3) => {
      setLoading(true);
      try {
        const response = await api.get(`/chat/history`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
        const fetchedHistory = response.data;
        if (fetchedHistory && fetchedHistory.length > 0) {
          setThreadId(fetchedHistory[0].thread_id);
          sessionStorage.setItem("threadId", fetchedHistory[0].thread_id);
        } else {
          console.warn("No threads found.");
          const response = await api.post("/chat/new/thread", null, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setThreadId(response.data.thread_id);
          sessionStorage.setItem(
            "threadId",
            response.data.thread_id.toString()
          );
          setRefresh(!refresh);
        }
      } catch (error) {
        console.error("Error getting history:", error);

        if (error.response && error.response.status === 500 && retryCount > 0) {
          console.warn(`Retrying... ${retryCount} attempts left.`);
          setTimeout(() => fetchHistory(retryCount - 1), 3000);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: "system",
              text_value:
                "An error occurred while fetching the chat history. Please try again later.",
            },
          ]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [token]);

  const { handleSendMessage } = useSendMessage(
    token,
    userLevel,
    threadId,
    setMessages,
    setIsLoading,
    setHasError,
    setWait,
    setDidSearch,
    setLinkSearch,
    setData,
    setNewMessage,
    runId,
    setRunId,
    messageId,
    setMessageId,
    setEventError,
    setRefresh,
    eventSourceRef
  );
  const handlePredefinedQuestionClick = (question) => {
    const tokenCount = encode(question).length;
    if (
      wordCount <= 5000 ||
      userLevel === "premium" ||
      userLevel === "employee"
    ) {
      handleSendMessage(question, null, "user", tokenCount);
    }
  };

  useEffect(() => {
    if (threadId) {
      setLinkSearch(false);
      setData(null);
      fetchThreadMessages(threadId);
    }
  }, [threadId]);

  const handleUpload = () => {
    if (userLevel === "premium" || userLevel === "employee") {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
  };
  return (
    <div className="flex h-screen text-black">
      {/* Sidebar */}
      <div
        className={`flex flex-col h-screen  dark:bg-gray-800 ${
          sidebarVisible ? "sm:w-64" : "w-0"
        } transition-width duration-500`}
      >
        <div
          className={`h-full sm:w-64 w-48 bg-white dark:bg-gray-800 transform transition-transform duration-500 z-30 ${
            sidebarVisible ? "translate-x-0" : "-translate-x-full"
          } sm:relative fixed`}
        >
          <div className="flex flex-col h-screen overflow-y-auto ">
            {sidebarVisible && (
              <SideBar
                key={refresh}
                userLevel={userLevel}
                token={token}
                threadId={threadId}
                setThreadId={setThreadId}
                setMessages={setMessages}
                sidebarVisible={sidebarVisible}
                setSidebarVisible={setSidebarVisible}
                openModal={openModal}
                closeModal={closeModal}
                setModalOpenProfile={setModalOpenProfile}
                wait={wait}
                setWait={setWait}
                fetchThreadMessages={fetchThreadMessages}
                loading={loading}
                darkMode={darkMode}
              />
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-grow h-screen w-4 ">
        {/* Header */}
        <div className="sticky top-0 z-20 bg-white border-b border-gray-200 shadow-sm">
          <Header
            userLevel={userLevel}
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            logout={logout}
            wordCount={wordCount}
            openModal={openModal}
            openSettings={openSettings}
            darkMode={darkMode}
            openSettings={openSettings}
            darkMode={darkMode}
          />
        </div>

        {/* Content Area */}
        <div className="flex flex-col flex-grow h-screen  overflow-y-auto">
          <div
            className="p-4 flex-grow overflow-auto border-r border-gray-300"
            style={{ height: "calc(80vh - 64px)" }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <MessageList
                  messages={messages}
                  setMessages={setMessages}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  messagesEndRef={messagesEndRef}
                  hasError={hasError}
                  setHasError={setHasError}
                  didSearch={didSearch}
                  setDidSearch={setDidSearch}
                  linkSearch={linkSearch}
                  setLinkSearch={setLinkSearch}
                  data={data}
                  setData={setData}
                  threadId={threadId}
                  messageId={messageId}
                  setMessageId={setMessageId}
                  setRunId={setRunId}
                  eventSourceRef={eventSourceRef}
                  setWait={setWait}
                  eventError={eventError}
                  wait={wait}
                  isRecording={isRecording}
                  setIsRecording={setIsRecording}

                  // handleRegenerate={handleRegenerate}
                  // stopStream={stopStream}
                />
                {messages.length === 0 && !loading && (
                  <PredefinedQuestions
                    predefinedQuestions={questions}
                    handlePredefinedQuestionClick={
                      handlePredefinedQuestionClick
                    }
                  />
                )}
              </>
            )}
          </div>

          <MessageInput
            userLevel={userLevel}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSendMessage={handleSendMessage}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            handleUpload={handleUpload}
            setModalOpen={setModalOpen}
            messageWordCount={wordCount}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            wait={wait}
            setWait={setWait}
            runId={runId}
            threadId={threadId}
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            isFetchingSpeech={isFetchingSpeech}
            setIsFetchingSpeech={setIsFetchingSpeech}
            speechToPrompt={speechToPrompt}
            setSpeechToPrompt={setSpeechToPrompt}
            eventSourceRef={eventSourceRef}
            setLinkSearch={setLinkSearch}
            setDidSearch={setDidSearch}
            setMessages={setMessages}
          />
        </div>
      </div>

      <Payments
        userLevel={userLevel}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <Profile
        userLevel={userLevel}
        modalOpen={modalOpenProfile}
        closeModal={closeModalProf}
        logout={logout}
        user={userLevel}
      />
      <Settings
        settingsModal={settingsModal}
        setSettingsModal={setSettingsModal}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        speechToPrompt={speechToPrompt}
        setSpeechToPrompt={setSpeechToPrompt}
        userLevel={userLevel}
      />
      <Survey token={token} />
    </div>
  );
}
