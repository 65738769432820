import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import FeedbackNegative from "./FeedbackNegative";

export default function Feedback({ threadId }) {
  const [feedback, setFeedback] = useState(null);
  const [showToolkit, setShowToolkit] = useState(false);

  useEffect(() => {
    setFeedback(null);
    setShowToolkit(false);
  }, [threadId]);

  const handleFeedback = (type) => {
    if (feedback) return;

    setFeedback(type);
    if (type === "thumbs-up") {
      return;
    } else if (type === "thumbs-down") {
      setShowToolkit(true);
    }
  };

  return (
    <>
      <div className="flex space-x-2 transition-opacity duration-300">
        {feedback !== "thumbs-down" && (
          <button
            title="Good Response"
            onClick={() => handleFeedback("thumbs-up")}
            className={`h-8 w-8 flex items-center justify-center rounded-full transition-all duration-300 transform ${
              feedback === "thumbs-up"
                ? "bg-green-500 text-white"
                : "bg-white text-gray-500 hover:bg-green-100"
            }`}
          >
            <FontAwesomeIcon icon={faThumbsUp} className="w-[14px] h-[14px]" />
          </button>
        )}

        {feedback !== "thumbs-up" && (
          <button
            title="Bad Response"
            onClick={() => handleFeedback("thumbs-down")}
            className={`h-8 w-8 flex items-center justify-center rounded-full transition-all duration-300 transform ${
              feedback === "thumbs-down"
                ? "bg-red-500 text-white"
                : "bg-white text-gray-500 hover:bg-red-100"
            }`}
          >
            <FontAwesomeIcon icon={faThumbsDown} className="w-4 h-4" />
          </button>
        )}
      </div>

      {showToolkit && <FeedbackNegative setShowToolkit={setShowToolkit} />}
    </>
  );
}
