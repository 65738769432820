import React, { useState } from "react";
import Modal from "react-modal";
import api from "../../../utils/api.js";
import { useAuth } from "../../../utils/AuthProvider.jsx";
import "../../../styles/small_loader.css";
import { toast, ToastContainer } from "react-toastify";
import Maintenance from "../utils/Maintenance.jsx";
import Billing from "./Billing.jsx";
Modal.setAppElement("#root");

export default function Payments({ userLevel, modalOpen, closeModal }) {
  const [paymentModal, setPaymentModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMt, setModalMt] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("monthly");

  const { token } = useAuth();

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const openPaymentModal = () => {
    closeModal();
    setPaymentModal(true);
  };

  const openMt = () => {
    setModalMt(true);
  };
  const closeMt = () => {
    setModalMt(false);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  // Check if the user is already subscribed
  const checkSubscription = async (token) => {
    try {
      const response = await api.get(`/user/level`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (response.data.user_level === "premium") {
          console.log("User is already subscribed to BETH Premium.");
          return true;
        }
      } else {
        console.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.log(error);
      console.error("An error occurred. Please try again later.");
    }
  };
  const handleSubscribe = async (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const discountCode = document.getElementById("discountCode").value;
    const phone = document
      .getElementById("phoneNumber")
      .value.replace(/\s+/g, "");

    const createCustomerRequest = {
      token: token,
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      discount_code: discountCode || "",
    };

    try {
      const isSubscribed = await checkSubscription(token);
      if (isSubscribed === true) {
        toast.warning("You are already subscribed to BETH Premium.");
        return;
      } else {
        setIsButtonDisabled(true);
        setLoading(true);

        const createCustomerResponse = await api.post(
          "/payment/create/customer",
          createCustomerRequest,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (createCustomerResponse.status === 200) {
          const customerId = createCustomerResponse.data.customer.id;
          sessionStorage.setItem("customerId", customerId);

          let createSubscriptionResponse;
          // Annual Plan
          if (selectedPlan === "annual") {
            createSubscriptionResponse = await api.post(
              "/payment/create/plan",
              {
                customer_id: customerId,
                plan_name: "premium_annual",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          // Monthly Plan
          else if (selectedPlan === "monthly") {
            createSubscriptionResponse = await api.post(
              "/payment/create/plan",
              {
                customer_id: customerId, // Using customerId from the current scope
                plan_name: "premium",
                discount_code: discountCode || null,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            throw new Error("Invalid plan selected");
          }

          // Handle the redirection if the subscription is successfully created
          if (createSubscriptionResponse?.status === 200) {
            window.location.href =
              createSubscriptionResponse.data.plan.actions[0].url;
          } else {
            throw new Error("Failed to create subscription");
          }
        } else {
          throw new Error("Failed to create customer");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsButtonDisabled(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Payments"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 relative max-h-full overflow-y-auto">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="text-center mb-6">
            <h2 className="text-2xl font-bold">Upgrade Your Plan</h2>
            <p className="text-gray-600">
              Choose the plan that suits your needs.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-stretch space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="border-2 shadow-sm rounded-lg p-4">
              <h3 className="text-xl font-bold">Free</h3>
              <p className="text-2xl font-bold">$0/month</p>
              {userLevel === "free" && (
                <div className="mt-2 bg-green-100 text-green-800 font-semibold p-2 rounded-md flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>Your Current Plan</span>
                </div>
              )}
              <ul className="mt-4 list-disc pl-4 text-left flex-grow">
                <li>Assistance with writing, problem solving, and more</li>
                <li>Limited access to GPT-4o mini</li>
                <li>
                  Limited access to advanced data analysis and web browsing
                </li>
              </ul>
            </div>

            {/* Premium Plan */}

            <div
              className={`border-2 shadow-lg rounded-lg p-4 mt-10 transition-colors duration-300 ${
                userLevel === "premium"
                  ? "border-green-500 bg-gradient-to-r from-green-50 to-white"
                  : ""
              }`}
            >
              <h3 className="text-xl font-extrabold text-gray-900">
                Premium Plan
              </h3>

              <p className="text-2xl font-bold text-green-600 ">
                $16.99/month
                {/* <span className="line-through text-gray-400 ml-2">$16.99</span> */}
              </p>

              {userLevel === "free" ? (
                <button
                  onClick={openPaymentModal}
                  className="my-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Upgrade to Premium
                </button>
              ) : (
                <div className="mt-2 bg-green-500 text-white font-semibold p-2 rounded-md flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>Your Current Plan</span>
                </div>
              )}

              <ul className="mt-2 list-disc pl-4 text-left flex-grow">
                <li>Early access to new features</li>
                <li>
                  Access to advanced data analysis, file uploads, vision, and
                  web browsing
                </li>
                <li>Comprehensive access to GPT-4</li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={paymentModal}
        onRequestClose={closePaymentModal}
        contentLabel="Payment Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full relative max-h-full overflow-y-auto scrollbar-hidden">
          <button
            onClick={closePaymentModal}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <Billing
            userLevel={userLevel}
            closeModal={closeModal}
            loading={loading}
            handleSubscribe={handleSubscribe}
            handlePhoneChange={handlePhoneChange}
            isButtonDisabled={isButtonDisabled}
            phoneNumber={phoneNumber}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          ></Billing>
        </div>
      </Modal>
      <Maintenance isOpen={modalMt} onRequestClose={closeMt} />

      <ToastContainer />
    </>
  );
}
